<!-- 友情链接 -->
<template>
  <div class="about-outer about-links clearfix auto">
    <div class="about-main-container pull-left">
      <div class="links-main clearfix">
        <div class="links-wrap-div clearfix">
          <!--下面为模拟数据-->
          <!--<a href="" target="_blank" title="" class="links-bg-a"><img alt="" src="../template/Home/Zkeys/PC/Static/css/module/about/img/index-banner-sub2.png" /></a>-->
        </div>
      </div>

      <div class="share-link-wrapper clearfix">
        <div>
          <p class="ab-part-title">交换链接说明</p>
          <p class="links-introduce-p">
            符合PR>=5，Alexa排名20,000以内的网站，可以和企业服务平台相互添加网站链接，具体操作方式如下：
          </p>

          <div class="share-link-left">
            <p class="share-link-left-title">在贵站添加企业服务平台链接</p>
            <p class="share-link-left-title-introduce">
              您可以根据下方提示，在您的网站中插入企业服务平台的文字链接、图片链接代码。
            </p>
            <div class="share-code-title">文字链接代码：</div>
            <div class="txt-link-code clearfix">
              <div class="link-code-box link-code-box-2">
                &lt;" href="http://i360.cn"&gt;企业服务平台&lt;/a&gt;
              </div>
              <!-- <div class="link-preview-box-2 clearfix">
                <div class="preview-text">文字预览效果：</div>
                <div class="preview-info-2">
                  <a target="_blank" href="../index.html">企业服务平台</a>
                </div>
              </div> -->
              <div
                class="copy-code-1"
                id="copyTxtCode"
                data-clipboard-text='<a target="">企业服务平台</a>'
              >
                复制代码
              </div>
              <span class="copied-remind" id="txtCodeRemind">您已复制成功</span>
            </div>
            <div class="ie7-fix"></div>
            <div class="share-code-title">
              <!--<span class="logo-code-icon"></span>-->
              图片链接代码：
            </div>

            <div class="logo-link-code clearfix">
              <div class="link-code-box link-code-box-1">
                &lt;a target="_blank" href="http://i360.cn"&gt;<br />&nbsp;&nbsp;&nbsp;&nbsp;&lt;img
                src="http://i360.cn/logo_small.gif"&gt;<br />&lt;/a&gt;
              </div>
              <!-- <div class="link-preview-box clearfix">
                <div class="preview-text">图片预览效果：</div>
                <div class="preview-info">
                  <img src="http://i360.cn/logo_small.gif" />
                </div>
              </div> -->
              <div
                class="copy-code-1"
                id="copyLogoCode"
                data-clipboard-text='<a><img src=""></a>'
              >
                复制代码
              </div>
              <span class="copied-remind" id="logoCodeRemind"
                >您已复制成功</span
              >
            </div>
            <div class="ie7-fix"></div>
            <p class="share-link-left-title">将贵站链接邮件给我们</p>
            <p class="share-link-left-title-introduce step2-introduce">
              完成第一步后，将贵站链接邮件至
              <a href="mailto:online@panasia.com" class="color-blue"
                >online@panasia.com</a
              >。
            </p>
            <p class="share-link-left-title">企业服务平台审核回复</p>
            <p class="share-link-left-title-introduce">
              收到邮件后，我们会于5个工作日内尽快审核并回复。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
header .top-header {
  position: relative;
}

@import "../../../assets/static/template/Home/Zkeys/PC/Static/css/module/about/links.css";
</style>
